<template>
  <nav
    class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl d-none d-lg-flex px-2 pt-1"
    v-bind="$attrs"
    id="navbarBlur"
    data-scroll="true"
    style="justify-content: space-between !important;"
  >
    <breadcrumbs :currentPage="currentRouteName" :textWhite="textWhite" />
    <div class="">
      <div class="collapse navbar-collapse mt-sm-0 me-md-0" id="navbar">
        <ul class="navbar-nav justify-content-end">
          <li
            class="nav-item d-flex align-items-center nav-link font-weight-bold cursor-pointer justify-content-center"
            @click="Sair"
            :class="textWhite ? textWhite : 'text-body'"
          >
            <i class="bi bi-box-arrow-in-right" style="font-size: 18px"></i>
            <span class="font-weight-bolder" style="margin-left: 4px"
              >Sair</span
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <nav
    class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl d-sm-block d-md-none px-1 pt-1"
    v-bind="$attrs"
    id="navbarBlur"
    data-scroll="true"
  >
    <div class="d-flex w-100 justify-content-between align-items-center">
      <div class="nav-item d-xl-none d-flex flex-column align-items-center">
        <a
          @click="toggleSidebar"
          class="p-0 nav-link text-body"
          id="iconNavbarSidenav"
        >
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </a>
      </div>
      <div class="collapse navbar-collapse mt-sm-0 me-md-0" id="navbar">
        <ul class="navbar-nav justify-content-end">
          <li
            class="nav-item d-flex align-items-center nav-link font-weight-bold cursor-pointer justify-content-center"
            @click="Sair"
            :class="textWhite ? textWhite : 'text-body'"
          >
            <i class="bi bi-box-arrow-in-right" style="font-size: 18px"></i>
            <span class="font-weight-bolder" style="margin-left: 4px"
              >Sair</span
            >
          </li>
        </ul>
      </div>
    </div>
    <breadcrumbs :currentPage="currentRouteName" :textWhite="textWhite" />
  </nav>
</template>
<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations, mapActions } from "vuex";
import { useAuthStore } from "../../stores/auth";
import router from "../../router";
let AuthStore;

export default {
  name: "navbar",
  data() {
    return {
      showMenu: false,
      AuthStore,
    };
  },
  props: ["minNav", "textWhite"],
  created() {
    this.minNav;
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),

    Sair() {
      AuthStore.signOut();
      router.push("/loginadm");
    },

    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },
  },
  components: {
    Breadcrumbs,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  setup() {
    AuthStore = useAuthStore();
  },
};
</script>
