<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <BoxContratos />
      </div>
    </div>
  </div>
</template>

<script>
import BoxContratos from "./components/BoxContratos.vue";

export default {
  name: "tables",
  components: {
    BoxContratos,
  },
};
</script>
