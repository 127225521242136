// Importar o Axios
import axios from "axios";

// Definir a URL base padrão do Axios usando a variável de ambiente VUE_APP_ROOT_API
axios.defaults.baseURL = process.env.VUE_APP_ROOT_API;

// Criar duas instâncias do Axios com URLs base diferentes
const api = axios.create({ baseURL: process.env.VUE_APP_ROOT_API });
const apimonitoramento = axios.create({ baseURL: process.env.VUE_APP_ROOT_API_MONITORAMENTO });

// Adicionar um interceptor de requisição
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Adicionar um interceptor de resposta
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Exportar o Axios padrão e as instâncias personalizadas (api e apimonitoramento) juntamente com suas URLs base.
export { axios, api, apimonitoramento };