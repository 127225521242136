<template>
  <div class="card d-flex flex-column justify-content-center h-100" :class="dark ? 'bg-gradient-dark' : ''">
    <div class="card-body">
      <div class="d-flex mb-4">
        <h5 class="mb-0" :class="dark ? 'text-white' : 'color-icon'">{{ nome }}</h5>
      </div>
      <div class="mb-0 text-white items">
        <div class="mx-2 mt-2 d-flex flex-column  align-items-center">
          <a :href="aplicativo.link ? aplicativo.link : ''" target="_blank" class="btn-app mb-2"
            :class="aplicativo.textcolor ? 'text-white' : 'color-icon'" :style="{ 'background-color': aplicativo.color }">
            <i :class="aplicativo.icone"></i>
          </a>
          <!--<p class="mb-0">{{ aplicativo.name }}</p>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardHomeSingle",
  props: {
    aplicativo: Object,
    nome: String,
    dark: Boolean,
  },
};
</script>

<style scoped>
.btn-app {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24) !important;
}

.btn-app i {
  font-size: 22px;
}

.btn-app:hover {
  cursor: pointer;
}

.staramigo {
  background-image: url(../../assets/img/staramigo.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
  height: 40px;
}

.fitanywhere {
  background-image: url(../../assets/img/fit-anywhere.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 24px;
}

.yplay {
  background-image: url(../../assets/img/yplay.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 24px;
}

.items {
  display: flex;
  justify-content: center;
}

.color-icon {
  color: #344767 !important;
}

@media screen and (max-width: 767px) {
  .btn-app {
    width: 55px;
    height: 55px;
  }

  p {
    font-size: 14px;
  }
}
</style>
