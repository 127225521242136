const Telefonia = () => import("../views/Telefonia/Telefonia.vue");

const telefoniaroutes = [
  {
    path: "/telefonia",
    name: "Telefonia",
    component: Telefonia,
    meta: {
      requiresAuth: true,
      sistema: 'minhastar',
      layout: 'sistema'
    }
  },
];

export default telefoniaroutes;