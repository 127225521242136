const Perfil = () => import("../views/perfil/Perfil.vue");

const perfilroutes = [
  {
    path: "/perfil",
    name: "Perfil",
    component: Perfil,
    meta: {
      layout: "sistema",
      requiresAuth: true,
    },
  },
];

export default perfilroutes;