const Monitoramento = () => import("../views/Monitoramento.vue");

const monitoramentoroutes = [
    {
        path: "/monitoramento",
        name: "Monitoramento",
        component: Monitoramento,
        meta: {
            requiresAuth: true,
            layout: 'sistema'
        },
    },
];

export default monitoramentoroutes;