<template>
  <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" tabindex="-1" aria-hidden="true"
    ref="modalRefDetalhes">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Detalhes Login</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form role="Login" @submit="(e) => { e.preventDefault() }" class="text-start">
            <div class="mb-1">
              <label>ID</label>
              <input disabled class="form-control" id="Id" type="text" v-model="Login.Id" placeholder="Id"
                aria-label="Id" />
            </div>
            <div class="mb-1">
              <label>PPPoE</label>
              <input disabled class="form-control" id="EditarNome" type="text" v-model="Login.Pppoe" placeholder="Pppoe"
                aria-label="Nome" />
            </div>
            <div class="mb-1">
              <label>IP</label>
              <input disabled class="form-control" id="EditarNome" type="text" v-model="Login.Ip" placeholder="IP"
                aria-label="Nome" />
            </div>
            <div class="mb-1">
              <label>Mac</label>
              <input disabled class="form-control" id="EditarNome" type="text" v-model="Login.Mac" placeholder="Mac"
                aria-label="Nome" />
            </div>
            <div class="mb-1">
              <label>Tempo conectado</label>
              <input disabled class="form-control" id="EditarNome" type="text" v-model="Login.TempoOnline"
                placeholder="Tempo conectado" aria-label="Nome" />
            </div>
          </form>
        </div>
        <div class="modal-footer" style="justify-content: space-between !important;">
          <p class="text-sm text-bold">Precisa de ajuda? Entre em contato conosco
          </p>
          <soft-button @click="EntreEmContato(Login)" style="background-color: #0d6efd !important;" size="sm"><a
              style="color: #fff;">Entrar em
              contato</a></soft-button>
          <soft-button color="secondary" size="sm" @click="FecharModal">Fechar</soft-button>
        </div>
      </div>
      {{ AbrirBotao }}
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import bootstrap from 'bootstrap/dist/js/bootstrap';
import { ref } from 'vue'

export default {
  emits: ["close"],
  props: {
    modal: Boolean,
    Detalhes: Object
  },
  components: {
    SoftButton
  },
  data() {
    return {
      Login: {}
    }
  },
  mounted() {
    this.modalDetalhes = new bootstrap.Modal(this.$refs.modalRefDetalhes)
  },
  methods: {
    EntreEmContato(Login) {
      window.open('https://wa.me//555127472747?text=Ol%C3%A1%2C+preciso+de+ajuda+com+o+login+ID+' + Login.Id, '_blank');
    },
    LimpaVariaveis() {
      this.Login = {
        Id: '',
        Pppoe: '',
        Ip: '',
        Mac: '',
        TempoOnline: '',
      }
    },
    AbrirModal() {
      this.modalDetalhes.show()
      this.Login = this.Detalhes;
    },
    FecharModal() {
      this.modalDetalhes.hide();
      this.LimpaVariaveis();
      this.$emit("close");
    },
  },
  computed: {
    AbrirBotao() {
      return this.modal ? this.AbrirModal() : ''
    }
  },
  setup() {
    const modalRefDetalhes = ref(null);

    return {
      modalRefDetalhes
    }
  }
}
</script>