<template>
  <div
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-collapse
          navText="Dashboard"
          :to="{ name: 'Dashboard' }"
          @click="toggleSidebar"
        >
          <template #icon>
            <shop />
          </template>
        </sidenav-collapse>
      </li>
      <li
        class="nav-item"
        v-if="AuthStore.getMe().sistema == 'minhastar'"
        @click="toggleSidebar"
      >
        <sidenav-collapse navText="Contratos" :to="{ name: 'Contratos' }">
          <template #icon>
            <office />
          </template>
        </sidenav-collapse>
      </li>
      <li
        class="nav-item"
        v-if="AuthStore.getMe().sistema == 'minhastar'"
        @click="toggleSidebar"
      >
        <sidenav-collapse navText="Faturas" :to="{ name: 'Faturas' }">
          <template #icon>
            <credit-card />
          </template>
        </sidenav-collapse>
      </li>
      <li
        style="display: none"
        class="nav-item"
        v-if="AuthStore.getMe().sistema == 'minhastar'"
        @click="toggleSidebar"
      >
        <sidenav-collapse navText="Telefonia" :to="{ name: 'Telefonia' }">
          <template #icon>
            <Phone />
          </template>
        </sidenav-collapse>
      </li>
      <li
        class="nav-item"
        v-if="AuthStore.getMe().sistema == 'minhastar'"
        @click="toggleSidebar"
      >
        <sidenav-collapse
          navText="Monitoramento"
          :to="{ name: 'Monitoramento' }"
        >
          <template #icon>
            <ChartSimple />
          </template>
        </sidenav-collapse>
      </li>
      <li
        class="nav-item"
        v-if="AuthStore.getMe().sistema == 'minhastar'"
        @click="toggleSidebar"
      >
        <sidenav-collapse
          navText="Telefonia"
          :to="{ name: 'Telefonia' }"
        >
          <template #icon>
            <Phone />
          </template>
        </sidenav-collapse>
      </li>
      <li
        class="nav-item"
        v-if="AuthStore.getMe().sistema == 'minhastar'"
        @click="toggleSidebar"
      >
        <sidenav-collapse navText="Home" :to="{ name: 'Home' }">
          <template #icon>
            <Spaceship />
          </template>
        </sidenav-collapse>
      </li>
      <li class="mt-3 nav-item">
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6 ms-2"
        >
          Pessoal
        </h6>
      </li>
      <li
        class="nav-item"
        v-if="AuthStore.getMe().sistema == 'minhastar'"
        @click="toggleSidebar"
      >
        <sidenav-collapse navText="Perfil" :to="{ name: 'Perfil' }">
          <template #icon>
            <customer-support />
          </template>
        </sidenav-collapse>
      </li>
      <!--<li class="nav-item" v-if="AuthStore.getMe().sistema == 'minhastar'" @click="toggleSidebar">
        <sidenav-collapse navText="Usuarios" :to="{ name: 'Usuarios' }">
          <template #icon>
            <Users />
          </template>
        </sidenav-collapse>
      </li>-->
      <li class="nav-item">
        <div class="nav-link cursor-pointer" @click="Sair">
          <div
            class="text-center bg-white shadow icon icon-shape icon-sm border-radius-md d-flex align-items-center justify-content-center me-2"
          >
            <Logout />
          </div>
          <span class="nav-link-text ms-1">Sair</span>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";
import Shop from "../../components/Icon/Shop.vue";
import Office from "../../components/Icon/Office.vue";
import CreditCard from "../../components/Icon/CreditCard.vue";
import CustomerSupport from "../../components/Icon/CustomerSupport.vue";
import Logout from "../../components/Icon/Logout.vue";
import Spaceship from "../../components/Icon/Spaceship.vue";
import Phone from "../../components/Icon/Phone.vue";
/*import Users from "../../components/Icon/Users.vue"*/
import ChartSimple from "../../components/Icon/ChartSimple.vue";
import { mapMutations, mapActions } from "vuex";

import { useAuthStore } from "../../stores/auth";
import router from "../../router";
let AuthStore;

export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data() {
    return {
      controls: "dashboardsExamples",
      isActive: "active",
      AuthStore,
      larguraDispositivo: undefined,
    };
  },
  components: {
    SidenavCollapse,
    Shop,
    Office,
    CreditCard,
    CustomerSupport,
    Logout,
    Spaceship,
    /*Users,*/
    ChartSimple,
    Phone,
  },
  mounted() {
    this.larguraDispositivo = window.innerWidth;
  },
  methods: {
    ...mapMutations(["navbarMinimize"]),
    ...mapActions(["toggleSidebarColor"]),

    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },

    Sair() {
      AuthStore.signOut();
      router.push("/loginadm");
    },

    toggleSidebar() {
      if (this.larguraDispositivo <= 1023) {
        this.toggleSidebarColor("bg-white");
        this.navbarMinimize();
      }
    },
  },
  setup() {
    AuthStore = useAuthStore();
  },
};
</script>
