<template>
  <div class="card h-100">
    <div class="p-3 pb-0 card-header">
      <h6 class="mb-0">Aplicativos</h6>
      <p class="text-xs">Por favor, escolha a plataforma desejada pressionando o botão à direita.</p>
    </div>
    <div class="p-3 card-body">
      <ul class="list-group">
        <li class="px-0 mb-2 border-0 list-group-item d-flex align-items-center">
          <soft-avatar class="me-3" :img="yplay" alt="kal" border-radius="lg" shadow="regular" />
          <div class="d-flex align-items-start flex-column justify-content-center">
            <h6 class="mb-0 text-sm">YPlay</h6>
          </div>
          <div class="ms-auto">
            <a class="mb-0 btn btn-link pe-3 ps-0 text-lg"
              href="https://www.amazon.com.br/CAST-COM-EQUIP-ELET-LTDA/dp/B099TX1KYN/ref=sr_1_1?__mk_pt_BR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=MN0IDKX9PPMS&keywords=Yplay+tv&qid=1665079071&qu=eyJxc2MiOiIyLjU5IiwicXNhIjoiMC4wMCIsInFzcCI6IjAuMDAifQ%3D%3D&sprefix=yplay+t%2Caps%2C342&sr=8-1"
              target="_blank"><i class="fab fa-amazon"></i></a>
            <a class="mb-0 btn btn-link pe-3 ps-0 text-lg" href="https://apps.apple.com/br/app/yplay/id1557886427"
              target="_blank"><i class="fab fa-app-store"></i></a>
            <a class="mb-0 btn btn-link pe-3 ps-0 text-lg"
              href="https://play.google.com/store/apps/details?id=br.yplay.yplay" target="_blank"><i
                class="fab fa-google-play"></i></a>
          </div>
        </li>
        <li class="px-0 mb-2 border-0 list-group-item d-flex align-items-center">
          <soft-avatar class="me-3" :img="fitanywhere" alt="kal" border-radius="lg" shadow="regular" />
          <div class="d-flex align-items-start flex-column justify-content-center">
            <h6 class="mb-0 text-sm">Fit Anywhere</h6>
          </div>
          <div class="ms-auto">
            <a class="mb-0 btn btn-link pe-3 ps-0 text-lg" href="https://apps.apple.com/br/app/fit-anywhere/id1062520116"
              target="_blank"><i class="fab fa-app-store"></i></a>
            <a class="mb-0 btn btn-link pe-3 ps-0 text-lg"
              href="https://play.google.com/store/apps/details?id=com.nostrostudio.fitanywhere.smartphone&hl=pt_BR&gl=US"
              target="_blank"><i class="fab fa-google-play"></i></a>
          </div>
        </li>
        <li class="px-0 mb-2 border-0 list-group-item d-flex align-items-center">
          <soft-avatar class="me-3" :img="telemedicina" alt="kal" border-radius="lg" shadow="regular" />
          <div class="d-flex align-items-start flex-column justify-content-center">
            <h6 class="mb-0 text-sm">Telemedicina</h6>
          </div>
          <div class="ms-auto">
            <a class="mb-0 btn btn-link pe-3 ps-0 text-lg" href="https://apps.apple.com/br/app/ucast-telemedicina/id6448754403"
              target="_blank"><i class="fab fa-app-store"></i></a>
            <a class="mb-0 btn btn-link pe-3 ps-0 text-lg"
              href="https://play.google.com/store/apps/details?id=br.yc.youcast.telemedicina"
              target="_blank"><i class="fab fa-google-play"></i></a>
          </div>
        </li>
        <li class="px-0 mb-2 border-0 list-group-item d-flex align-items-center">
          <soft-avatar class="me-3" :img="starcontrol" alt="kal" border-radius="lg" shadow="regular" />
          <div class="d-flex align-items-start flex-column justify-content-center">
            <h6 class="mb-0 text-sm">Star Control</h6>
          </div>
          <div class="ms-auto">
            <a class="mb-0 btn btn-link pe-3 ps-0 text-lg" href="https://apps.apple.com/br/app/star-control/id1496232286"
              target="_blank"><i class="fab fa-app-store"></i></a>
            <a class="mb-0 btn btn-link pe-3 ps-0 text-lg"
              href="https://play.google.com/store/apps/details?id=io.anlix.starcontrol"
              target="_blank"><i class="fab fa-google-play"></i></a>
          </div>
        </li>
        <li class="px-0 mb-2 border-0 list-group-item d-flex align-items-center">
          <soft-avatar class="me-3" :img="speedstar" alt="kal" border-radius="lg" shadow="regular" />
          <div class="d-flex align-items-start flex-column justify-content-center">
            <h6 class="mb-0 text-sm">Star Speed</h6>
          </div>
          <div class="ms-auto">
            <a class="mb-0 btn btn-link pe-3 ps-0 text-lg" href="http://starinternetetelefonia.speedtestcustom.com/"
              target="_blank"><i class="fas fa-globe"></i></a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import SoftAvatar from "@/components/SoftAvatar.vue";
import yplay from "@/assets/img/yplay.png";
import fitanywhere from "@/assets/img/fit-anywhere.jpg";
import telemedicina from "@/assets/img/telemedicina.png";
import speedstar from "@/assets/img/speedstar.png";
import starcontrol from "@/assets/img/starcontrol.png"

export default {
  components: {
    SoftAvatar
  },
  data() {
    return {
      yplay,
      fitanywhere,
      telemedicina,
      speedstar,
      starcontrol,
    };
  },
}
</script>