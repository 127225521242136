<template>
  <GraficoConsumo
    @valoratualizado="AtualizaGrafico"
    id="chart-line"
    title="Gráfico dos últimos 7 dias de consumo"
    v-if="BuscaConsumoDiario[0] != undefined && exibeGrafico"
    :description="
      BuscaConsumoDiario[0].data + ' até ' + BuscaConsumoDiario[6].data
    "
    :chart="{
      labels: [
        BuscaConsumoDiario[0].data,
        BuscaConsumoDiario[1].data,
        BuscaConsumoDiario[2].data,
        BuscaConsumoDiario[3].data,
        BuscaConsumoDiario[4].data,
        BuscaConsumoDiario[5].data,
        BuscaConsumoDiario[6].data,
      ],
      datasets: [
        {
          label: 'Download (GB)',
          data: [
            BuscaConsumoDiario[0].consumo,
            BuscaConsumoDiario[1].consumo,
            BuscaConsumoDiario[2].consumo,
            BuscaConsumoDiario[3].consumo,
            BuscaConsumoDiario[4].consumo,
            BuscaConsumoDiario[5].consumo,
            BuscaConsumoDiario[6].consumo,
          ],
        },
        {
          label: 'Upload (GB)',
          data: [
            BuscaConsumoDiario[0].consumo_upload,
            BuscaConsumoDiario[1].consumo_upload,
            BuscaConsumoDiario[2].consumo_upload,
            BuscaConsumoDiario[3].consumo_upload,
            BuscaConsumoDiario[4].consumo_upload,
            BuscaConsumoDiario[5].consumo_upload,
            BuscaConsumoDiario[6].consumo_upload,
          ],
        },
      ],
    }"
  />
  <Loading
    :active="isLoading"
    :is-full-page="fullPage"
    :loader="loader"
    :color="colorLoading"
  />
</template>

<script>
import { ref } from "vue";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import GraficoConsumo from "@/examples/Charts/GraficoConsumo.vue";
import { useLoginStore } from "../../stores/login";
let LoginStore;

export default {
  name: "ConsumoLogin",
  data() {
    return {
      LoginStore,
      exibeGrafico: true,
    };
  },
  components: {
    GraficoConsumo,
    Loading,
  },
  methods: {
    async AtualizaGrafico(idLogin) {
      this.isLoading = true;
      this.exibeGrafico = false;
      await LoginStore.AtualizaConsumoDiario(idLogin);
      this.exibeGrafico = true;
      this.isLoading = false;
    },
  },
  setup() {
    LoginStore = useLoginStore();
    let isLoading = ref(false);
    let fullPage = true;
    let loader = "spinner";
    let colorLoading = "#007BFF";

    return {
      isLoading,
      fullPage,
      loader,
      colorLoading,
    };
  },
  computed: {
    BuscaConsumoDiario() {
      //return LoginStore.getConsumoDiario()
      const consumoDiario = LoginStore.getConsumoDiario();

      if (consumoDiario.length < 7) {
        const objetosFaltantes = 7 - consumoDiario.length;

        for (let i = 0; i < objetosFaltantes; i++) {
          consumoDiario.push({
            data: "",
            consumo: "0.00",
            consumo_upload: "0.00",
          });
        }
      }

      return consumoDiario;
    },
  },
};
</script>
