<template>
  <svg v-bind="$attrs" :width="width" :height="height" xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 448 512">
    <title>basket</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g fill="#FFFFFF" fill-rule="nonzero">
        <path class="color-background"
          d="M160 80c0-26.5 21.5-48 48-48h32c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V80zM0 272c0-26.5 21.5-48 48-48H80c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V272zM368 96h32c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H368c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48z" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: "12px",
    },
    width: {
      type: String,
      default: "12px",
    },
  },
};
</script>