import { defineStore } from "pinia";
import { api, apimonitoramento } from "../boot/axios";

export const useAuthStore = defineStore("AuthStore", {
  state: () => ({
    me: {},
    token: "",
    isAuthenticated: false,
  }),
  getters: {
    getMe: (state) => () => {
      return state.me;
    },
    getNivel: (state) => () => {
      return state.me.Nivel;
    },
    getToken: (state) => () => {
      return state.token; //== "" ? "" : JSON.parse(state.token);
    },
    getIsAuthenticated: (state) => () => {
      return state.isAuthenticated;
    },
  },
  actions: {
    async doLogin(payload) {
      return await apimonitoramento
        .post("premium/login", payload)
        .then((response) => {
          const { token } = response.data;
          this.token = token;
          api.defaults.headers.common.Authorization = "Bearer " + token;
          apimonitoramento.defaults.headers.common.Authorization = "Bearer " + token;
          this.isAuthenticated = true;
          window.localStorage.setItem("token", JSON.stringify(token));
          this.getUsuario(token);
          return false;
        })
        .catch((error) => {
          if (error.response) {
            return error.response.data;
          }
        });
    },

    async LoginPremium(payload) {
      return await api
        .post("usuario/loginixc", payload)
        .then((response) => {
          const { token } = response.data;
          this.token = token;
          api.defaults.headers.common.Authorization = "Bearer " + token;
          apimonitoramento.defaults.headers.common.Authorization = "Bearer " + token;
          this.isAuthenticated = true;
          window.localStorage.setItem("token", JSON.stringify(token));
          this.getUsuario(token);
          return false;
        })
        .catch((error) => {
          if (error.response) {
            return error.response.data;
          }
        });
    },

    signOut() {
      api.defaults.headers.common.Authorization = "";
      apimonitoramento.defaults.headers.common.Authorization = "";
      this.token = "";
      this.me = {};
      this.isAuthenticated = false;
      window.localStorage.removeItem("token");
    },

    async getUsuario(token) {
      return await api
        .post("usuario/status", token)
        .then((response) => {
          window.auth = response.data.dados.sistema
          this.me = {
            ClienteIdIxc: response.data.dados.ClienteIdIxc,
            ...response.data.dados.dados,
            sistema: response.data.dados.sistema
          }
        })
        .catch(() => {
          this.token = "";
          this.isAuthenticated = false;
          window.localStorage.removeItem("token");
        });
    },

    async init() {
      const token = JSON.parse(localStorage.getItem("token"));
      if (token) {
        this.token = token;
        api.defaults.headers.common.Authorization = "Bearer " + token;
        apimonitoramento.defaults.headers.common.Authorization = "Bearer " + token;
        this.isAuthenticated = true;
        window.localStorage.setItem("token", JSON.stringify(token));
        this.getUsuario(token);
      } else {
        this.token = "";
        this.isAuthenticated = false;
        window.localStorage.removeItem("token");
      }
    },
  },
});