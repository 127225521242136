import { defineStore } from "pinia";
import { api } from "../boot/axios";

export const useTelefoniaStore = defineStore("TelefoniaStore", {
  state: () => ({
    Telefonia: undefined,
    ContratosTelefonia: [],
  }),
  getters: {
    getTelefonia: (state) => () => {
      return state.Telefonia;
    },
    getContratosTelefonia: (state) => () => {
      return state.ContratosTelefonia;
    },
  },
  actions: {
    async AtualizaTelefonia() {
      return await api
        .post("telefonia/busca")
        .then((response) => {
          this.Telefonia = response.data;
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            return error.response.data;
          }
        });
    },
    async AtualizaContratosTelefonia() {
      try {
        const telefonia = await api.post("telefonia/busca");
        const contratos = await api.post("contrato");

        let ContratosComTelefonia = [];
        if (telefonia.data.status) {
          ContratosComTelefonia = await telefonia.data.dados.ramais.map(
            (telefonia) => {
              return contratos.data.contratos.registros.find(
                (contrato) => contrato.id == telefonia.contrato
              );
            }
          );
        }

        this.ContratosTelefonia = await ContratosComTelefonia.map(
          (contrato) => {
            contrato.telefonia = telefonia.data.dados.ramais.find(
              (telefone) => telefone.contrato == contrato.id
            );
            return contrato;
          }
        );
      } catch (error) {
        throw error;
      }
    },
  },
});
