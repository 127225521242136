const Faturas = () => import("../views/Faturas.vue");

const faturasroutes = [
    {
        path: "/faturas",
        name: "Faturas",
        component: Faturas,
        meta: {
            requiresAuth: true,
            layout: 'sistema'
        },
    },
];

export default faturasroutes;