import { defineStore } from "pinia";
import { api } from "../boot/axios";

export const useLoginStore = defineStore("LoginStore", {
  state: () => ({
    ConsumoDiario: [],
    Logins: [],
  }),
  getters: {
    getConsumoDiario: (state) => () => {
      return state.ConsumoDiario;
    },
    getLogins: (state) => () => {
      return state.Logins;
    },
  },
  actions: {
    async AtualizaLogins() {
      await api
        .post("login")
        .then(async (response) => {
          this.Logins = response.data.dados;
        })
        .catch((error) => {
          console.log(error)
        })
    },

    async AtualizaConsumoDiario(idLogin) {
      await api
        .post("login/consumo", { tipo_consumo: 2, IdLogin: idLogin })
        .then((response) => {
          if (response.data.status) {
            let ultimos7Dias = response.data.consumo;
            ultimos7Dias.sort((a, b) => new Date(a.data) - new Date(b.data));
            ultimos7Dias = ultimos7Dias.slice(-7);

            let formatarData = (data) => {
              const dataObj = new Date(data);
              const dia = dataObj.getDate().toString().padStart(2, '0');
              const mes = (dataObj.getMonth() + 1).toString().padStart(2, '0');
              return `${dia}/${mes}`;
            };

            let bytesToGigabytes = (bytes) => (bytes / (1024 * 1024 * 1024)).toFixed(2);

            let dadosFormatados = ultimos7Dias.map((item) => ({
              data: formatarData(item.data),
              consumo: bytesToGigabytes(parseInt(item.consumo)),
              consumo_upload: bytesToGigabytes(parseInt(item.consumo_upload)),
            }));

            this.ConsumoDiario = dadosFormatados;
          } else {
            this.ConsumoDiario = []
          }
        })
        .catch((error) => {
          console.error(error);
          if (error.response) {
            return error.response.data;
          }
        });
    }


  }
});