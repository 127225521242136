/*const Usuarios = () => import("../views/usuarios/Usuarios.vue");*/

const usuariosroutes = [
  /*{
    path: "/usuarios",
    name: "Usuarios",
    component: Usuarios,
    meta: {
      requiresAuth: true,
      sistema: 'minhastar',
      layout: 'sistema'
    }
  },*/
];

export default usuariosroutes;