<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card
          class="box-hover"
          title="Contratos ativos"
          :value="ContratosStore.getTotalContratos()"
          :percentage="{
            color: 'text-success',
          }"
          :icon="{
            component: 'fas fa-file-contract',
            background: iconBackground,
          }"
          direction-reverse
          @click="AbrePagina('/contratos')"
        />
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card
          title="Atendimentos"
          value="0"
          :percentage="{
            color: 'text-success',
          }"
          :icon="{
            component: 'fas fa-headset',
            background: iconBackground,
          }"
          direction-reverse
        />
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card
          class="box-hover"
          title="Faturas abertas"
          :value="FaturasAbertas"
          :percentage="{
            color: 'text-danger',
          }"
          :icon="{
            component: 'fas fa-dollar-sign',
            background: iconBackground,
          }"
          direction-reverse
          @click="AbrePagina('/faturas')"
        />
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card
          class="box-hover"
          title="Notas"
          :value="ContratosStore.getTotalNotas()"
          :percentage="{
            color: 'text-success',
          }"
          :icon="{
            component: 'fas fa-clipboard',
            background: iconBackground,
          }"
          direction-reverse
          @click="AbrePagina('/faturas')"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-7 mb-lg-0 mb-4">
        <div class="card h-100">
          <div class="card-body p-3 h-100">
            <div class="row px-3 h-100">
              <div class="col-lg-6">
                <div class="d-flex flex-column h-100">
                  <h5 class="font-weight-bolder pt-4">
                    Telefonia e Central PABX
                  </h5>
                  <p class="mb-5">
                    Conheça nossos planos de telefonia e Central PABX. Mais
                    praticidade, múltiplas ferramentas e segurança para sua casa
                    ou seu negócio. Internet e telefonia: tudo na STAR.
                  </p>
                  <a
                    class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto"
                    target="_blank"
                    href="https://star.psi.br/telefonia"
                  >
                    Saiba mais
                    <i
                      class="fas fa-arrow-right text-sm ms-1"
                      aria-hidden="true"
                    ></i>
                  </a>
                </div>
              </div>
              <div
                class="col-lg-5 bg-cover ms-auto mt-lg-0 border-radius-lg"
                :style="{
                  backgroundImage:
                    'url(' +
                    require('@/assets/img-minhastar/telefonia-dashboard.jpg') +
                    ')',
                }"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5">
        <div class="card h-100 p-3">
          <div
            class="overflow-hidden position-relative border-radius-lg bg-cover h-100"
            :style="{
              backgroundImage:
                'url(' +
                require('@/assets/img-minhastar/close-up-na-jovem-empresaria.jpg') +
                ')',
            }"
          >
            <span class="mask bg-gradient-dark"></span>
            <div class="card-body position-relative z-index-1 p-3 h-100">
              <div class="d-flex flex-column h-100">
                <h5 class="text-white font-weight-bolder mb-4 pt-2">
                  Combos de até 700 mega a partir de R$99 mensais
                </h5>
                <p class="text-white mb-5">
                  Tranquilidade, velocidade e atendimento ágil e humanizado.
                  Conheça todos os benefícios de set STAR.
                </p>
                <a
                  class="text-white font-weight-bold ps-1 mb-0 icon-move-left mt-auto"
                  target="_blank"
                  href="https://star.psi.br/combos-star"
                >
                  Saiba mais
                  <i
                    class="fas fa-arrow-right text-sm ms-1"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3 row">
      <!--<div class="mb-4 col-lg-8 mb-lg-0">
        <div class="card z-index-2">
          <ConsumoLogin />
        </div>
      </div>-->
      <div class="col-lg-4 col-xl-4">
        <Aplicativos />
      </div>
    </div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :loader="loader"
      :color="colorLoading"
    />
  </div>
</template>
<script>
import Aplicativos from "./components/Aplicativos.vue";
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import ConsumoLogin from "./components/ConsumoLogin.vue";
import { useFinanceiroStore } from "../stores/financeiro";
import { useContratosStore } from "../stores/contratos";
import router from "../router";
let FinanceiroStore;
let ContratosStore;

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default {
  name: "dashboard-default",
  setup() {
    document.title = "Minha STAR | Dashboard";
    FinanceiroStore = useFinanceiroStore();
    ContratosStore = useContratosStore();
  },
  data() {
    return {
      iconBackground: "bg-gradient-success",
      FinanceiroStore,
      ContratosStore,
      isLoading: true,
      fullPage: true,
      loader: "spinner",
      colorLoading: "#007BFF",
    };
  },
  methods: {
    AbrePagina(rota) {
      router.push(rota);
    },
  },
  mounted() {
    this.isLoading = false;
  },
  computed: {
    FaturasAbertas() {
      return FinanceiroStore.getBoletosAbertos().status
        ? FinanceiroStore.getBoletosAbertos().financeiro.length
        : 0;
    },
  },
  components: {
    MiniStatisticsCard,
    Loading,
    ConsumoLogin,
    Aplicativos,
  },
};
</script>

<style scoped>
.box-hover:hover {
  cursor: pointer;
}
</style>
