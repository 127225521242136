<template>
  <div class="col-lg-4 col-sm-12 mb-3">
    <p class="text-sm text-bold" style="margin-bottom: 0">Exibir contratos:</p>

    <div
      class="form-check form-check-inline"
      v-for="opcao in opcoesFiltradas"
      :key="opcao.value"
    >
      <input
        class="form-check-input"
        v-model="opcaoSelecionada"
        type="radio"
        name="flexRadioDefault"
        :id="'flexRadioDefault' + opcao.value"
        :value="opcao.value"
      />
      <label
        class="form-check-label m-0"
        :for="'flexRadioDefault' + opcao.value"
      >
        {{ opcao.label }}
      </label>
    </div>
  </div>
  <div class="container-contratos mb-2">
    <div class="card" v-for="(contrato, index) in ListaContratos" :key="index">
      <div class="badge-contrato mb-3">
        <div
          id="badge-internet"
          class="d-flex align-items-center"
          v-if="contrato.tipo == 'I'"
        >
          <i class="bi bi-wifi" style="margin-right: 6px !important"></i>
          <p class="text-sm text-bold">Internet</p>
        </div>
        <div
          id="badge-servico"
          class="d-flex align-items-center"
          v-else-if="contrato.tipo == 'S'"
        >
          <i
            class="bi bi-file-earmark-text-fill"
            style="margin-right: 6px !important"
          ></i>
          <p class="text-sm text-bold">Serviço</p>
        </div>
        <div
          id="badge-telefonia"
          class="d-flex align-items-center"
          v-else-if="contrato.tipo == 'T'"
        >
          <i class="bi bi-wifi" style="margin-right: 6px !important"></i>
          <p class="text-sm text-bold">Telefone</p>
        </div>
        <div id="badge-sva" class="d-flex align-items-center" v-else>
          <i class="bi bi-wifi" style="margin-right: 6px !important"></i>
          <p class="text-sm text-bold">SVA</p>
        </div>
      </div>
      <div>
        <p class="text-xs text-bold">
          {{ contrato.data_ativacao.split("-").reverse().join("/") }}
        </p>
        <p class="text-sm text-bold mt-2">{{ contrato.contrato }}</p>
        <p class="text-xs text-bold">ID: {{ contrato.id }}</p>
        <p v-if="contrato.endereco != ''" class="text-xs text-bold">
          {{ contrato.endereco }} - Nº{{ contrato.numero }}
        </p>
        <div
          class="d-flex align-items-center"
          :class="contrato.status == 'A' ? 'text-success' : 'text-body'"
        >
          <i
            :class="
              contrato.status == 'A' ? 'bi-emoji-smile' : 'bi-emoji-frown'
            "
            class="bi me-1 text-xs"
          ></i>
          <p class="text-xs text-bold">
            {{
              contrato.status == "A"
                ? "Ativo"
                : contrato.status == "I"
                ? "Inativo"
                : contrato.status == "P"
                ? "Pré-contrato"
                : contrato.status == "D"
                ? "Desistiu"
                : ""
            }}
          </p>
        </div>
        <div
          class="d-flex align-items-center mt-1"
          v-if="
            contrato.status_internet == 'CA' ||
            contrato.status_internet == 'CM' ||
            contrato.status_internet == 'AA'
          "
        >
          <i
            :class="
              contrato.status_internet == 'CA' ||
              contrato.status_internet == 'CM'
                ? 'text-danger bi-ban'
                : 'text-warning bi-pencil-fill'
            "
            class="bi me-1 text-xs"
          ></i>
          <p
            class="text-xs text-bold"
            :class="
              contrato.status_internet == 'CA' ||
              contrato.status_internet == 'CM'
                ? 'text-danger'
                : 'text-warning'
            "
          >
            {{
              contrato.status_internet == "CA"
                ? "Bloqueio Automático"
                : contrato.status_internet == "CM"
                ? "Bloqueio Automático"
                : contrato.status_internet == "AA"
                ? " Aguardando Assinatura"
                : ""
            }}
          </p>
        </div>
        <div
          v-if="
            (contrato.status_internet == 'CA' ||
              contrato.status_internet == 'CM') &&
            contrato.desbloqueio_confianca != 'N'
          "
        >
          <button
            class="btn-desbloqueio-confianca"
            @click="DesbloqueioConfianca(contrato.id)"
          >
            <p>Solicitar desbloqueio de confiança</p>
          </button>
        </div>
      </div>

      <div class="mt-3" v-if="contrato.Logins && contrato.Logins.length != 0">
        <p class="text-sm text-bold">Logins:</p>
        <div class="container-login">
          <div
            class="card-login"
            v-for="(login, index) in contrato.Logins"
            :key="index"
          >
            <p class="text-xs text-bold">ID: {{ login.id }}</p>
            <p class="text-xs text-bold">PPPoE: {{ login.login }}</p>
            <div
              class="d-flex align-items-center"
              :class="login.online == 'S' ? 'text-success' : 'text-danger'"
            >
              <i class="bi bi-wifi" style="margin-right: 5px"></i>
              <p class="text-xs text-bold">
                {{ login.online == "S" ? "Online" : "Offline" }}
              </p>
            </div>
            <p class="text-xs text-bold" v-if="login.endereco != ''">
              {{ login.endereco }} - nº {{ login.numero }}
            </p>
            <button
              @click="AbrirModal(login)"
              type="button"
              class="btn w-100 mt-1"
              style="background-color: #0d6efd !important; color: #fff"
            >
              Ver detalhes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <nav
    aria-label="Page navigation example"
    v-if="ContratosStore.getContratos()?.registros?.length > 5"
  >
    <ul class="pagination justify-content-center">
      <li class="page-item" :class="{ disabled: currentPage === 1 }">
        <a
          class="page-link"
          @click.prevent="changePage(currentPage - 1)"
          href="#"
          aria-label="Previous"
        >
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      <li
        class="page-item"
        v-for="page in totalPages"
        :key="page"
        :class="currentPage === page ? 'active' : ''"
      >
        <a class="page-link" href="#" @click.prevent="changePage(page)">{{
          page
        }}</a>
      </li>
      <li class="page-item" :class="{ disabled: currentPage === totalPages }">
        <a
          class="page-link"
          href="#"
          aria-label="Next"
          @click.prevent="changePage(currentPage + 1)"
        >
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>
  <DetalhesLogin
    :modal="modalDetalhes"
    :Detalhes="LoginSelecionado"
    @close="FecharModal"
  />
  <Loading
    :active="isLoading"
    :is-full-page="fullPage"
    :loader="loader"
    :color="colorLoading"
  />
</template>

<script>
import { ref } from "vue";
import { api } from "../../boot/axios";
import DetalhesLogin from "./DetalhesLogin.vue";
import { useContratosStore } from "../../stores/contratos";
import { useLoginStore } from "../../stores/login";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
let LoginStore;
let ContratosStore;

export default {
  name: "BoxContratos",
  components: {
    DetalhesLogin,
    Loading,
  },
  data() {
    return {
      ContratosStore,
      itemsPerPage: 5,
      currentPage: 1,
      modalDetalhes: undefined,
      LoginSelecionado: undefined,
      opcoes: [
        { value: 0, label: "Todos", tipo: "A" },
        { value: 1, label: "Internet", tipo: "I" },
        { value: 2, label: "Telefonia", tipo: "T" },
        { value: 3, label: "Serviços", tipo: "S" },
      ],
      opcaoSelecionada: 0,
    };
  },
  methods: {
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    AbrirModal(login) {
      this.LoginSelecionado = {
        Id: login.id,
        Pppoe: login.login,
        Ip: login.ip,
        Mac: login.mac,
        TempoOnline: this.converterOnline(login.tempo_conectado),
      };
      this.modalDetalhes = true;
    },
    async DesbloqueioConfianca(IdContrato) {
      this.isLoading = true;
      await api
        .post("contrato/desbloqueio", {
          IdContrato: IdContrato,
        })
        .then(async (response) => {
          if (response.data.status == "sucesso") {
            await ContratosStore.AtualizaContratos();
          }
          this.$notify({
            type: response.data.status == "erro" ? "error" : "success",
            text: response.data.mensagem,
            duration: 15000,
          });
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            type: "error",
            text: "Algo deu errado! Tente novamente mais tarde.",
            duration: 3000,
          });
        });
      this.isLoading = false;
    },
    converterOnline(segundos) {
      const horas = Math.floor(segundos / 3600);
      const minutos = Math.floor((segundos % 3600) / 60);

      if (horas > 24) {
        const dias = Math.floor(horas / 24);
        const horasRestantes = horas % 24;
        return `${dias}d ${horasRestantes}h`;
      }

      return `${horas}h ${minutos}m`;
    },
    FecharModal() {
      this.modalDetalhes = false;
    },
    associarLoginsAContratos(contratos, logins) {
      this.isLoading = true;
      contratos.forEach((contrato) => {
        contrato.Logins = [];
      });

      logins.forEach((login) => {
        const contrato = contratos.find(
          (contrato) => contrato.id === login.id_contrato
        );

        if (contrato) {
          contrato.Logins.push(login);
        }
      });
      this.isLoading = false;
    },
  },
  computed: {
    opcoesFiltradas() {
      const tiposContratos = ContratosStore.getContratos()?.registros?.map(
        (contrato) => contrato.tipo
      );
      const tiposFiltrados = [...new Set(tiposContratos)];

      if (tiposFiltrados.length === 0) {
        return this.opcoes;
      }

      const opcoesFiltradas = this.opcoes.filter((opcao) =>
        tiposFiltrados.includes(opcao.tipo)
      );

      if (!tiposFiltrados.includes("A")) {
        opcoesFiltradas.push(this.opcoes.find((opcao) => opcao.value === 0)); // Adicionar a opção "Todos" se não estiver presente nos tipos filtrados
      }

      return opcoesFiltradas;
    },
    totalPages() {
      return Math.ceil(
        this.ContratosStore.getContratos().registros.length / this.itemsPerPage
      );
    },
    ListaContratos() {
      const contratos = ContratosStore.getContratos()?.registros || [];
      const logins = LoginStore.getLogins();

      if (logins != undefined && logins.length != 0 && contratos.length != 0) {
        this.associarLoginsAContratos(contratos, logins);
      }

      const retornoMap = {
        1: "I",
        2: "T",
        3: "S",
      };

      const retorno = retornoMap[this.opcaoSelecionada] || "SVA";

      let arrayfiltrado = [];
      if (this.opcaoSelecionada !== 0) {
        arrayfiltrado = contratos.filter((objeto) => objeto.tipo === retorno);
      } else {
        arrayfiltrado = contratos;
      }

      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return arrayfiltrado.slice(start, end);
    },
  },
  setup() {
    ContratosStore = useContratosStore();
    LoginStore = useLoginStore();

    let isLoading = ref(false);
    let fullPage = true;
    let loader = "spinner";
    let colorLoading = "#007BFF";

    return {
      isLoading,
      fullPage,
      loader,
      colorLoading,
    };
  },
};
</script>

<style scoped>
.container-contratos {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
  margin: 0 auto;
  box-sizing: border-box;
}

.container-login {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  box-sizing: border-box;
}

.card {
  background-color: #fff;
  padding: 20px;
  width: 100%;
}

.card-login {
  padding: 10px;
  width: 100%;
  border: 2px solid #f8f9fa;
  border-radius: 12px;
  margin: 4px 0;
  flex: 1;
  min-width: 260px;
}

.card-login button {
  margin-bottom: 0px;
}

.card p {
  margin-bottom: 0;
}

.badge-contrato {
  color: #fff;
}

.badge-contrato #badge-internet {
  max-width: auto;
  background-color: rgba(79, 148, 239, 0.2);
  color: rgb(79, 148, 239);
  padding: 2px 6px;
  border-radius: 12px;
}

.badge-contrato #badge-servico {
  background-color: rgba(243, 186, 41, 0.2);
  color: rgb(243, 186, 41);
  padding: 2px 6px;
  border-radius: 12px;
}

.badge-contrato #badge-telefonia {
  background-color: rgba(23, 166, 4, 0.2);
  color: rgb(23, 166, 4);
  padding: 2px 6px;
  border-radius: 12px;
}

.btn-desbloqueio-confianca {
  border: none;
  background-color: #0d6efd;
  width: auto;
  padding: 2px 8px;
  border-radius: 6px;
  transition: all 0.1s ease;
}

.btn-desbloqueio-confianca:hover {
  transform: scale(1.04, 1.02);
  transition: all 0.3s ease;
}

.btn-desbloqueio-confianca p {
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  margin: 0;
  font-size: 10px;
}

@media (max-width: 1023px) {
  .card {
    min-width: 100%;
    flex-basis: calc(50% - 20px);
  }

  .card-login {
    min-width: 100%;
  }

  .btn-desbloqueio-confianca {
    width: 100%;
  }
}
</style>
