<template>
  <component :is="$route.meta.layout"></component>
  <notifications position="top center" animation-type="css" :max="3" />
  <Loading :active="isLoading" :is-full-page="fullPage" :loader="loader" :color="colorLoading" />
</template>
<script>
import bonus from "./layout/bonus.vue";
import sistema from "./layout/sistema.vue";
import Sidenav from "./examples/Sidenav";
import Navbar from "@/examples/Navbars/Navbar.vue";
import AppFooter from "@/examples/Footer.vue";
import { mapMutations } from "vuex";
import { ref } from "vue";
import { api } from "./boot/axios";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import { useAuthStore } from "./stores/auth";
import { useFinanceiroStore } from "./stores/financeiro";
import { useContratosStore } from "./stores/contratos";
import { useLoginStore } from "./stores/login";
import router from "./router";


let FinanceiroStore;
let ContratosStore;
let AuthStore;
let LoginStore;

export default {
  name: "App",
  components: {
    Sidenav,
    Navbar,
    AppFooter,
    Loading,
    sistema,
    bonus,
  },
  setup() {
    let isLoading = ref(true);
    let fullPage = true;
    let loader = "spinner";
    let colorLoading = "#007BFF";

    return {
      isLoading,
      fullPage,
      loader,
      colorLoading,
    };
  },
  data() {
    return {
      AuthStore,
      FinanceiroStore,
      ContratosStore,
      LoginStore,
    };
  },
  async beforeCreate() {
    AuthStore = useAuthStore();
    FinanceiroStore = useFinanceiroStore();
    ContratosStore = useContratosStore();
    LoginStore = useLoginStore();
    await AuthStore.init();

    let token = await AuthStore.getToken();
    if (token) {
      api.defaults.headers.common.Authorization = "Bearer " + token;

      await api
        .post("usuario/status", {})
        .then(() => {
          //this.AtualizaDados();
        })
        .catch(function () {
          AuthStore.signOut();
          router.push("/login");
        })
        .finally(async () => { });
    } else {
      api.defaults.headers.common.Authorization = "";
    }
    this.isLoading = false;
  },
  methods: {
    ...mapMutations(["toggleConfigurator"]),
    DirecionaLogin() {
      router.push("/login");
    },
    async AtualizaDados() {
      await FinanceiroStore.AtualizaBoletosAbertos();
      await FinanceiroStore.AtualizaBoletosPagos();
      await ContratosStore.AtualizaContratos();
      await ContratosStore.AtualizaNotas();
      await LoginStore.AtualizaLogins();
      await LoginStore.AtualizaConsumoDiario(LoginStore.getLogins()[0].id);
    },
  },
  computed: {
    navClasses() {
      return {
        "position-sticky blur shadow-blur mt-4 left-auto top-1 z-index-sticky": this
          .$store.state.isNavFixed,
        "position-absolute px-4 mx-0 w-100 z-index-2": this.$store.state
          .isAbsolute,
        "px-0 mx-4 mt-4": !this.$store.state.isAbsolute,
      };
    },
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-white";
  },
};
</script>

<style>
.vue-notification-group {
  margin-top: 5px;
}

.vue-notification-wrapper,
.vue-notification-template {
  border-radius: 8px !important;
  margin-top: 5px;
}

.notification-content {
  font-family: "Open Sans";
  font-weight: 600;
}
</style>
