<template>
  <div></div>
  <Loading
    :active="isLoading"
    :is-full-page="fullPage"
    :loader="loader"
    :color="colorLoading"
  />
</template>

<script>
import { storeToRefs } from "pinia";
import { useAuthStore } from "../stores/auth.js";
import { useFinanceiroStore } from "../stores/financeiro";
import { useContratosStore } from "../stores/contratos";
import { useLoginStore } from "../stores/login";
import { useTelefoniaStore } from "../stores/telefonia";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import { ref } from "vue";
let isAuthenticated;
let FinanceiroStore;
let ContratosStore;
let AuthStore;
let LoginStore;
let TelefoniaStore;
let auth;
export default {
  components: {
    Loading,
  },
  methods: {
    async AtualizaDados() {
      await FinanceiroStore.AtualizaBoletosAbertos();
      await FinanceiroStore.AtualizaBoletosPagos();
      await ContratosStore.AtualizaContratos();
      await ContratosStore.AtualizaNotas();
      await LoginStore.AtualizaLogins();
      if (
        LoginStore.getLogins() != undefined &&
        LoginStore.getLogins().length != 0
      ) {
        await LoginStore.AtualizaConsumoDiario(LoginStore.getLogins()[0].id);
      }

      await TelefoniaStore.AtualizaTelefonia();
      await TelefoniaStore.AtualizaContratosTelefonia();
      this.isLoading = false;
    },
  },

  data() {
    return {
      isAuthenticated,
      auth,
      AuthStore,
      FinanceiroStore,
      ContratosStore,
      LoginStore,
      TelefoniaStore,
    };
  },

  mounted() {
    if (this.auth) {
      this.AtualizaDados();
    }
  },

  setup() {
    AuthStore = useAuthStore();
    isAuthenticated = storeToRefs(AuthStore);
    auth = isAuthenticated.isAuthenticated;
    FinanceiroStore = useFinanceiroStore();
    ContratosStore = useContratosStore();
    LoginStore = useLoginStore();
    TelefoniaStore = useTelefoniaStore();

    let isLoading = ref(true);
    let fullPage = true;
    let loader = "spinner";
    let colorLoading = "#007BFF";

    return {
      isLoading,
      fullPage,
      loader,
      colorLoading,
    };
  },

  watch: {
    auth(logad) {
      if (logad) {
        this.AtualizaDados();
      }
    },
  },
};
</script>

/* watch(isAuthenticated, () => { })*/
