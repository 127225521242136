<template>
  <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" tabindex="-1" aria-hidden="true" ref="modalRef">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">{{ Informacoes?.name }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="mb-0 text-white items">
            <div class="mx-2 mt-2 d-flex flex-column  align-items-center" v-for="(app, index) of Informacoes?.botoes"
              :key="index">
              <a :href="app.link" target="_blank" class="btn-app mb-md-2" :class="app.textcolor ? 'text-white' : ''"
                :style="{ 'background-color': app.color }">
                <i :class="app.icone"></i>
              </a>
              <p class="mb-0 text-center" :class="dark ? 'text-white' : 'text-dark'">{{ app.name }}</p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <soft-button color="secondary" size="sm" @click="FecharModal">Fechar</soft-button>
        </div>
      </div>
      {{ AbrirBotao }}
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import { apimonitoramento } from "../../boot/axios";
import bootstrap from 'bootstrap/dist/js/bootstrap';
import { ref } from 'vue'

export default {
  emits: ["close"],
  props: {
    modal: Boolean,
    Informacoes: Object,
  },
  components: {
    SoftButton,
  },
  data() {
    return {
      login: {
        Nome: '',
        Login: '',
        Senha: '',
        Email: '',
      },
    }
  },
  mounted() {
    this.modalCadastro = new bootstrap.Modal(this.$refs.modalRef)
  },
  methods: {
    Cadastrar() {
      try {
        if (this.login.Nome == '' || this.login.Login == '' || this.login.Senha == '' || this.login.Email == '') {
          this.$notify({ type: "error", text: "Informações faltando! Tente novamente.", duration: 5000, });
          return false;
        } else {
          return apimonitoramento
            .post("premium/cadastrar", this.login)
            .then((response) => {
              if (response.data.Cadastrado) {
                this.$notify({ type: "success", text: "Usuario cadastrado", duration: 5000, });
                this.FecharModal()
                this.$emit("close");
              }
            })
            .catch((error) => {
              if (error.response.data.LoginEncontrado) {
                this.$notify({ type: "error", text: error.response.data.msg, duration: 5000, });
              } else {
                console.error(error)
              }
            })
        }
      } catch (error) {
        console.error(error)
      }
    },
    LimpaVariaveis() {
      this.login = {
        Nome: '',
        Login: '',
        Senha: '',
        Email: '',
      }
    },
    AbrirModal() {
      this.modalCadastro.show()
      console.log(this.Informacoes)
    },
    FecharModal() {
      this.modalCadastro.hide();
      this.LimpaVariaveis();
      this.$emit("close");
    },
  },
  computed: {
    AbrirBotao() {
      return this.modal ? this.AbrirModal() : ''
    }
  },
  setup() {
    const modalRef = ref(null);

    return {
      modalRef
    }
  }
}
</script>

<style scoped>
.btn-app {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24) !important;
}

.btn-app i {
  font-size: 22px;
}

.btn-app:hover {
  cursor: pointer;
}

.items {
  display: flex;
  justify-content: center;
}
</style>