const LoginAdm = () => import("../views/autenticacao/LoginAdm.vue");
const LoginPremium = () => import("../views/autenticacao/LoginPremium.vue");

const autenticacaoroutes = [
  {
    path: "/loginpremium",
    name: "LoginPremium",
    component: LoginPremium,
    meta: {
      layout: 'sistema'
    },
  },
  {
    path: "/loginadm",
    name: "LoginAdm",
    component: LoginAdm,
    meta: {
      layout: 'bonus'
    },
  },
];

export default autenticacaoroutes;