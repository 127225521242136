const Bonus = () => import("../views/Bonus/index.vue");

const bonusroutes = [
    {
        path: "/bonusstaramigo",
        name: "Bonus",
        component: Bonus,
        meta: {
            layout: 'bonus'
        },
    },
];

export default bonusroutes;