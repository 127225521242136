import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import SoftUIDashboard from "./soft-ui-dashboard";
import { createPinia } from 'pinia'
import Notifications from '@kyvg/vue3-notification'
import "@fontsource/open-sans";
import 'bootstrap-icons/font/bootstrap-icons.css'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Maska from 'maska'
import Select2 from 'vue3-select2-component';

const pinia = createPinia()

const appInstance = createApp(App);

appInstance.component('Select2', Select2)
appInstance.use(store);
appInstance.use(router);
appInstance.use(pinia);
appInstance.use(Maska);
appInstance.use(FontAwesomeIcon);
appInstance.use(Notifications);
appInstance.use(SoftUIDashboard);
appInstance.mount("#app");
