<template>
  <div class="bg-color-bonus">
    <router-view />
  </div>
</template>

<style scoped>
.bg-color-bonus{
  background-color: rgba(248,249,250, 1);
  color: #212529;
}

.container {
  max-width: 960px !important;
}
</style>