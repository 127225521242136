const Telefonia = () => import("../views/Manuais/ManualTelefonia.vue");

const manuaisroutes = [
  {
    path: "/manual_telefonia",
    name: "ManualTelefonia",
    component: Telefonia,
    meta: {
      layout: "bonus",
    },
  },
];

export default manuaisroutes;
