<template>
  <!-- basic height 20 -->
  <svg v-bind="$attrs" :width="width" :height="height" viewBox="0 0 512 512" version="1.1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>spaceship</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-1720.000000, -592.000000)" fill="#FFFFFF" fill-rule="nonzero">
        <g transform="translate(1716.000000, 291.000000)">
          <g transform="translate(4.000000, 301.000000)">
            <path class="color-background"
              d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    height: {
      type: String,
      default: "12px",
    },
    width: {
      type: String,
      default: "12px",
    },
  },
};
</script>
