<template>
  <sidenav :custom_class="this.$store.state.mcolor" :class="[
    this.$store.state.isTransparent,
    this.$store.state.isRTL ? 'fixed-end' : 'fixed-start',
  ]" v-if="this.$store.state.showSidenav" />

  <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
    <!-- nav -->
    <navbar :class="[navClasses]" :textWhite="this.$store.state.isAbsolute ? 'text-white opacity-8' : ''"
      :minNav="navbarMinimize" v-if="this.$store.state.showNavbar" />
    <router-view @click="FechaNavbar()" />
    <app-footer v-show="this.$store.state.showFooter" />
    <Iniciador />
  </main>
</template>

<script>
import { mapMutations } from "vuex";
import Iniciador from "../boot/iniciador.vue";
import { ref } from "vue";
import AppFooter from "@/examples/Footer.vue";
import Sidenav from "@/examples/Sidenav";
import Navbar from "@/examples/Navbars/Navbar.vue";

export default {
  components: {
    Iniciador,
    Sidenav,
    Navbar,
    AppFooter,
  },
  data() {
    return {
      larguraDispositivo: undefined,
    }
  },
  setup() {
    let isLoading = ref(true);
    let dadosCarregados = ref(false);
    let fullPage = true;
    let loader = "spinner";
    let colorLoading = "#007BFF";

    return {
      isLoading,
      dadosCarregados,
      fullPage,
      loader,
      colorLoading,
    };
  },
  methods: {
    handleResize() {
      this.larguraDispositivo = window.innerWidth;
      if (this.larguraDispositivo > 1023 && this.$store.state.showSidenav) {
        this.navbarToggle(true);
      }
    },
    FechaNavbar() {
      if (this.larguraDispositivo <= 1023 && this.$store.state.showSidenav) {
        this.navbarToggle(true);
      }
    },
    ...mapMutations(["navbarMinimize", "navbarToggle"]),
  },
  computed: {
    navClasses() {
      return {
        "position-sticky blur shadow-blur mt-4 left-auto top-1 z-index-sticky": this
          .$store.state.isNavFixed,
        "position-absolute px-4 mx-0 w-100 z-index-2": this.$store.state
          .isAbsolute,
        "px-0 mx-4 mt-4": !this.$store.state.isAbsolute,
      };
    },
  },
  mounted() {
    this.larguraDispositivo = window.innerWidth;
    window.addEventListener('resize', this.handleResize);
  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-white";
  },
}
</script>