import { createRouter, createWebHashHistory } from "vue-router";
import routes from "./routes";

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  const usuario = window.auth == undefined
    ? localStorage.getItem("auth")
    : window.auth;

  const usuariochave = localStorage.getItem('token');
  const vrotas = to.matched.some(record => record.meta.requiresAuth);

  if (vrotas) {
    if (usuariochave === undefined || usuariochave === null) {
      return next('/loginadm');
    } else if (to.meta.nivel < parseInt(usuario)) {
      this.$notify({ type: "error", text: "Perfil de acesso não permitido", duration: 5000 });
      return next('/');
    }
  }

  next();
});

export default router;
