<template>
  <div
    @click="FecharTodos"
    class="card d-flex flex-column justify-content-center h-100"
    :class="dark ? 'bg-gradient-dark' : ''"
  >
    <div class="card-body">
      <div class="d-flex mb-md-4">
        <h5 class="mb-0" :class="dark ? 'text-white' : ''">{{ nome }}</h5>
      </div>

      <div v-if="ExibeTodos == false" class="mb-0 text-white items">
        <div class="mx-2 mt-2 d-flex flex-column align-items-center">
          <a
            @click.stop="Expandir()"
            class="btn-app mb-md-2"
            :class="padrao.textcolor ? 'text-white' : 'color-icon'"
            :style="{ 'background-color': padrao.color }"
          >
            <i :class="padrao.icone"></i>
          </a>
          <!--<p v-if="padrao.name != ''" class="mb-0 text-center" :class="dark ? 'text-white' : 'text-dark'">{{ padrao.name
          }}</p>-->
        </div>
      </div>

      <div v-if="ExibeTodos" class="mb-0 text-white items buttons-app">
        <div
          class="mx-2 mt-2 d-flex flex-column align-items-center"
          v-for="(app, index) of aplicativos"
          :key="index"
        >
          <a
            @click.stop="
              app.tipo != 'button' ? AbrirLink(app.link) : AbrirModal(app)
            "
            class="btn-app mb-md-2"
            :class="app.textcolor ? 'text-white' : 'color-icon'"
            :style="{ 'background-color': app.color }"
          >
            <i :class="app.icone"></i>
          </a>
          <p
            class="mb-0 text-center"
            :class="dark ? 'text-white' : 'text-dark'"
          >
            {{ app.name }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <EscolherPlataforma
    :modal="ExibeModal"
    :Informacoes="InformacoesApp"
    @close="FecharModal"
  />
</template>

<script>
import EscolherPlataforma from "./EscolherPlataforma.vue";

export default {
  name: "CardHomeTest",
  emits: ["open"],
  props: {
    aplicativos: {
      type: Array,
      default: () => [],
    },
    dark: Boolean,
    nome: String,
    padrao: Object,
    close: String,
  },
  components: {
    EscolherPlataforma,
  },
  data() {
    return {
      ExibeModal: undefined,
      InformacoesApp: undefined,
      ExibeTodos: false,
    };
  },
  methods: {
    Expandir() {
      this.ExibeTodos = true;
      this.$emit("open", this.nome);
    },
    FecharTodos() {
      if (this.ExibeTodos) {
        this.ExibeTodos = false;
      }
    },
    AbrirModal(app) {
      this.InformacoesApp = app;
      this.ExibeModal = true;
    },
    FecharModal() {
      this.ExibeModal = false;
    },
    AbrirLink(link) {
      window.open(link, "_blank");
    },
  },
  watch: {
    close(value, oldValue) {
      if (value != this.nome) {
        this.ExibeTodos = false;
      }
    },
  },
};
</script>

<style scoped>
.btn-app {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
}

.btn-app i {
  font-size: 22px;
}

.btn-app:hover {
  cursor: pointer;
}

.fitanywhere {
  background-image: url(../../assets/img/fit-anywhere.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 24px;
}

.yplay {
  background-image: url(../../assets/img/yplay.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 24px;
}

.watchtv {
  background-image: url(../../assets/img/watchtv.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 34px;
  height: 34px;
}

.logostar {
  background-image: url(../../assets/img/logo-star.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 34px;
  height: 34px;
}

.owncloud {
  background-image: url(../../assets/img/owncloud.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 54px;
  height: 54px;
}

.logoplutotv {
  background-image: url(../../assets/img/logoplutotv.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
  height: 40px;
}

.items {
  display: flex;
  justify-content: center;
}

.color-icon {
  color: #344767 !important;
}

@media screen and (max-width: 767px) {
  .btn-app {
    width: 55px;
    height: 55px;
  }

  p {
    font-size: 14px;
  }
}

@media screen and (max-width: 1023px) {
  .buttons-app {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }
}
</style>
