import { defineStore } from "pinia";
import { api } from "../boot/axios";

export const useFinanceiroStore = defineStore("FinanceiroStore", {
  state: () => ({
    BoletosAbertos: [],
    BoletosPagos: [],
  }),
  getters: {
    getBoletosAbertos: (state) => () => {
      return state.BoletosAbertos;
    },
    getBoletosPagos: (state) => () => {
      return state.BoletosPagos;
    },
  },
  actions: {
    async AtualizaBoletosAbertos() {
      await api
        .post("financeiro/boletosabertos")
        .then((response) => {
          if (response.data.status) {
            let array = response.data
            array.financeiro.sort((a, b) => {
              const dataVencimentoA = new Date(a.data_vencimento);
              const dataVencimentoB = new Date(b.data_vencimento);
              return dataVencimentoB - dataVencimentoA;
            });

            this.BoletosAbertos = array;
          }
        })
        .catch((error) => {
          console.log(error)
          if (error.response) {
            return error.response.data;
          }
        });
    },

    async AtualizaBoletosPagos() {
      await api
        .post("financeiro/faturaspagas")
        .then((response) => {
          if (response.data.status) {
            let array = response.data
            array.financeiro.sort((a, b) => {
              const dataVencimentoA = new Date(a.data_vencimento);
              const dataVencimentoB = new Date(b.data_vencimento);
              return dataVencimentoB - dataVencimentoA;
            });

            this.BoletosPagos = array;
          }
        })
        .catch((error) => {
          console.log(error)
          if (error.response) {
            return error.response.data;
          }
        });
    }
  }
});