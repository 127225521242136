<template>
  <div class="my-2 mx-2 border-radius-lg imagem-home fundo_tudo">
    <div class="d-flex justify-content-center">
      <div class="conteudo mx-auto">
        <div class="w-100 py-2">
          <div class="row">
            <div class="col-12 px-4">
              <div class="d-flex justify-content-end mb-5">
                <!-- Manter no topo com mt-5 -->
                <p class="Acesso text-bold text-lg" style="margin-bottom: 0">
                  <router-link :to="{ name: 'Dashboard' }" v-bind="$attrs"
                    >Minha conta STAR</router-link
                  >
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row d-flex justify-content-center">
          <div class="col-12">
            <div class="d-flex justify-content-start">
              <img
                class="logo-star"
                src="../assets/img-minhastar/logo.png"
                alt="Logo Minha Star"
              />
            </div>
            <div class="container-items mt-1">
              <div class="item-box">
                <CardHomeSingle
                  :dark="true"
                  nome="Ser Cliente"
                  :aplicativo="{
                    name: 'Acesse',
                    icone: 'fas fa-user',
                    color: '#DC3545',
                    textcolor: true,
                    link: 'https://star.psi.br/combos-star',
                  }"
                />
              </div>
              <div class="item-box">
                <CardHomeSingle
                  :dark="true"
                  nome="Star Amigo"
                  :aplicativo="{
                    name: 'Acesse',
                    icone: 'staramigo',
                    color: '#fff',
                    textcolor: false,
                    link: 'https://soustar.com.br/',
                  }"
                />
              </div>
              <div class="item-box">
                <CardHomeMultiple
                  :dark="true"
                  :close="CardAberto"
                  @open="Fechar"
                  nome="Arquivos"
                  :padrao="{
                    name: '',
                    icone: 'fas fa-file-contract',
                    color: '#017BFF',
                    textcolor: true,
                  }"
                  :aplicativos="[
                    {
                      name: 'Contrato Internet',
                      icone: 'fas fa-globe',
                      color: '#001F3F',
                      textcolor: true,
                      link: '/arquivos/contrato_internet_2020_01.pdf',
                    },
                    {
                      name: 'Contrato Serviço',
                      icone: 'bi bi-gear',
                      color: '#001F3F',
                      textcolor: true,
                      link: 'arquivos/contrato_sva_2020_01.pdf',
                    },
                    {
                      name: 'Manual Telefonia',
                      icone: 'bi bi-telephone-fill',
                      color: '#F77E14',
                      textcolor: true,
                      link: '/#/manual_telefonia',
                    },
                    {
                      name: 'Contrato Aplicativos',
                      icone: 'bi bi-phone',
                      color: '#001F3F',
                      textcolor: true,
                      link: 'arquivos/CONTRATO_APLICATIVOS.pdf',
                    },
                    {
                      name: 'Contrato Ifbot',
                      icone: 'bi bi-robot',
                      color: '#001F3F',
                      textcolor: true,
                      link: 'arquivos/CONTRATO_IFBOT.pdf',
                    },
                    {
                      name: 'Contrato Locaçao',
                      icone: 'bi bi-router',
                      color: '#001F3F',
                      textcolor: true,
                      link: 'arquivos/CONTRATO_LOCACAO.pdf',
                    },
                    {
                      name: 'Contrato Pabx',
                      icone: 'bi bi-telephone-fill',
                      color: '#001F3F',
                      textcolor: true,
                      link: 'arquivos/CONTRATO_PABX.pdf',
                    },
                    {
                      name: 'Contrato Rastreamento',
                      icone: 'bi bi-camera-video-fill',
                      color: '#001F3F',
                      textcolor: true,
                      link: 'arquivos/CONTRATO_RASTREAMENTO.pdf',
                    },
                    {
                      name: 'Contrato Telefonia',
                      icone: 'bi bi-telephone-fill',
                      color: '#001F3F',
                      textcolor: true,
                      link: 'arquivos/CONTRATO_STFC.pdf',
                    },
                    {
                      name: 'Contrato Hospedagem de site',
                      icone: 'bi bi-hdd-network-fill',
                      color: '#001F3F',
                      textcolor: true,
                      link: 'arquivos/CONTRATO_HOSPEDAGEM_SITE.pdf',
                    },
                    /*{
                      name: 'App',
                      icone: 'fas fa-mobile',
                      color: '#001F3F',
                      textcolor: true,
                      link: '#',
                    },
                    {
                      name: 'Cameras',
                      icone: 'fas fa-camera',
                      color: '#001F3F',
                      textcolor: true,
                      link: '#',
                    },
                    {
                      name: 'Rastreamento',
                      icone: 'fas fa-location-arrow',
                      color: '#001F3F',
                      textcolor: true,
                      link: '#',
                    },
                    {
                      name: 'Ifbot',
                      icone: 'fas fa-robot',
                      color: '#001F3F',
                      textcolor: true,
                      link: '#',
                    },*/
                  ]"
                />
              </div>
              <div class="item-box">
                <CardHomeMultiple
                  :dark="true"
                  :close="CardAberto"
                  @open="Fechar"
                  nome="Serviços"
                  :padrao="{
                    name: 'Exibir',
                    icone: 'bi bi-gear',
                    color: '#378754',
                    textcolor: true,
                  }"
                  :aplicativos="[
                    {
                      name: 'Star Mail',
                      icone: 'fas fa-envelope-open',
                      color: '#378754',
                      textcolor: true,
                      link: 'https://webmail.minhastar.com.br',
                    },
                    {
                      name: 'Star Control',
                      icone: 'fas fa-wifi',
                      color: '#DC3545',
                      textcolor: true,
                      tipo: 'button',
                      botoes: [
                        {
                          name: 'Android',
                          icone: 'fab fa-google-play',
                          color: '#fff',
                          link:
                            'https://play.google.com/store/apps/details?id=io.anlix.starcontrol&hl=pt_BR&gl=US',
                        },
                        {
                          name: 'IOS',
                          icone: 'fab fa-app-store',
                          color: '#017BFF',
                          textcolor: true,
                          link:
                            'https://apps.apple.com/uy/app/star-control/id1496232286',
                        },
                      ],
                    },
                    {
                      name: 'Star Cloud',
                      icone: 'owncloud',
                      color: '#001F3F',
                      textcolor: true,
                      link: 'https://cloud.minhastar.com.br',
                    },
                    /*{
                      name: 'Star Cam',
                      icone: 'fas fa-camera',
                      color: '#001F3F',
                      textcolor: true,
                      link: '#',
                    },
                    {
                      name: 'Rastreamento',
                      icone: 'fas fa-location-arrow',
                      color: '#017BFF',
                      textcolor: true,
                      link: '#',
                    },*/
                  ]"
                />
              </div>
            </div>

            <div class="container-items mt-3">
              <div class="item-box">
                <CardHomeMultiple
                  :dark="true"
                  :close="CardAberto"
                  @open="Fechar"
                  nome="Aplicativos Star"
                  :padrao="{
                    name: 'Exibir',
                    icone: 'fas fa-star',
                    color: '#378754',
                    textcolor: true,
                  }"
                  :aplicativos="[
                    {
                      name: 'Star Speed',
                      icone: 'fas fa-tachometer-alt',
                      color: '#001F3F',
                      textcolor: true,
                      link:
                        'http://starinternetetelefonia.speedtestcustom.com/',
                    },
                    {
                      name: 'Minha STAR',
                      icone: 'logostar',
                      color: '#fff',
                      textcolor: true,
                      tipo: 'button',
                      botoes: [
                        {
                          name: 'Android',
                          icone: 'fab fa-google-play',
                          color: '#fff',
                          link:
                            'https://play.google.com/store/apps/details?id=br.com.minhastar.app&hl=pt_BR&gl=US',
                        },
                        {
                          name: 'IOS',
                          icone: 'fab fa-app-store',
                          color: '#017BFF',
                          textcolor: true,
                          link:
                            'https://apps.apple.com/br/app/minha-star/id1580451367',
                        },
                      ],
                    },
                    {
                      name: 'StarCall',
                      icone: 'fas fa-tv',
                      color: '#DC3545',
                      textcolor: true,
                      tipo: 'button',
                      botoes: [
                        {
                          name: 'Android',
                          icone: 'fab fa-google-play',
                          color: '#fff',
                          link:
                            'https://play.google.com/store/apps/details?id=br.app.amax.star_call&pcampaignid=web_share',
                        },
                        {
                          name: 'IOS',
                          icone: 'fab fa-app-store',
                          color: '#017BFF',
                          textcolor: true,
                          link:
                            'https://apps.apple.com/br/app/starcall/id6467656143?platform=iphone',
                        },
                      ],
                    },
                  ]"
                />
              </div>
              <div class="item-box">
                <CardHomeMultiple
                  :dark="true"
                  :close="CardAberto"
                  @open="Fechar"
                  nome="Aplicativos"
                  :padrao="{
                    name: 'Exibir',
                    icone: 'fas fa-mobile',
                    color: '#FEC105',
                    textcolor: false,
                  }"
                  :aplicativos="[
                    {
                      name: 'Tele-medicina',
                      icone: 'fas fa-stethoscope',
                      color: '#fff',
                      textcolor: false,
                      tipo: 'button',
                      botoes: [
                        {
                          name: 'Android',
                          icone: 'fab fa-google-play',
                          color: '#fff',
                          link:
                            'https://play.google.com/store/apps/details?id=br.yc.youcast.telemedicina',
                        },
                        {
                          name: 'IOS',
                          icone: 'fab fa-app-store',
                          color: '#017BFF',
                          textcolor: true,
                          link:
                            'https://apps.apple.com/br/app/ucast-telemedicina/id6448754403',
                        },
                      ],
                    },
                    {
                      name: 'Fit Anywhere',
                      icone: 'fitanywhere',
                      color: '#DC3545',
                      textcolor: true,
                      tipo: 'button',
                      botoes: [
                        {
                          name: 'Android',
                          icone: 'fab fa-google-play',
                          color: '#fff',
                          link:
                            'https://play.google.com/store/apps/details?id=com.nostrostudio.fitanywhere.smartphone&hl=pt_BR&gl=US',
                        },
                        {
                          name: 'IOS',
                          icone: 'fab fa-app-store',
                          color: '#017BFF',
                          textcolor: true,
                          link:
                            'https://apps.apple.com/br/app/fit-anywhere/id1062520116',
                        },
                      ],
                    },
                    {
                      name: 'Watch Tv',
                      icone: 'watchtv',
                      color: '#fff',
                      textcolor: true,
                      tipo: 'button',
                      botoes: [
                        {
                          name: 'Android',
                          icone: 'fab fa-google-play',
                          color: '#fff',
                          link:
                            'https://play.google.com/store/apps/details?id=br.tv.watch.watchbrasil&hl=pt_BR&gl=US',
                        },
                        {
                          name: 'IOS',
                          icone: 'fab fa-app-store',
                          color: '#017BFF',
                          textcolor: true,
                          link:
                            'https://apps.apple.com/br/app/watch-brasil/id1354998732',
                        },
                      ],
                    },
                  ]"
                />
              </div>

              <div class="item-box">
                <CardHomeMultiple
                  :dark="true"
                  :close="CardAberto"
                  @open="Fechar"
                  nome="Entretenimento"
                  :padrao="{
                    name: 'Exibir',
                    icone: 'fas fa-video',
                    color: '#378754',
                    textcolor: true,
                  }"
                  :aplicativos="[
                    {
                      name: 'YPlay',
                      icone: 'yplay',
                      color: '#fff',
                      textcolor: true,
                      tipo: 'button',
                      botoes: [
                        {
                          name: 'Android',
                          icone: 'fab fa-google-play',
                          color: '#fff',
                          link:
                            'https://play.google.com/store/apps/details?id=br.yplay.yplay&hl=en_US',
                        },
                        {
                          name: 'IOS',
                          icone: 'fab fa-app-store',
                          color: '#017BFF',
                          textcolor: true,
                          link:
                            'https://apps.apple.com/br/app/yplay/id1557886427',
                        },
                      ],
                    },
                    {
                      name: 'Watch Tv',
                      icone: 'watchtv',
                      color: '#fff',
                      textcolor: true,
                      tipo: 'button',
                      botoes: [
                        {
                          name: 'Android',
                          icone: 'fab fa-google-play',
                          color: '#fff',
                          link:
                            'https://play.google.com/store/apps/details?id=br.com.minhastar.app&hl=pt_BR&gl=US',
                        },
                        {
                          name: 'IOS',
                          icone: 'fab fa-app-store',
                          color: '#017BFF',
                          textcolor: true,
                          link:
                            'https://play.google.com/store/apps/details?id=br.com.minhastar.app&hl=pt_BR&gl=US',
                        },
                      ],
                    },
                    {
                      name: 'Pluto Tv',
                      icone: 'logoplutotv',
                      color: '#000',
                      textcolor: true,
                      tipo: 'button',
                      botoes: [
                        {
                          name: 'Android',
                          icone: 'fab fa-google-play',
                          color: '#fff',
                          link:
                            'https://play.google.com/store/apps/details?id=tv.pluto.android&hl=pt_BR&gl=US',
                        },
                        {
                          name: 'IOS',
                          icone: 'fab fa-app-store',
                          color: '#017BFF',
                          textcolor: true,
                          link:
                            'https://apps.apple.com/br/app/pluto-tv-tv-ao-vivo-e-filmes/id751712884',
                        },
                      ],
                    },
                  ]"
                />
              </div>

              <div class="item-box">
                <CardHomeSingle
                  :dark="true"
                  nome="Politica de Privacidade"
                  :aplicativo="{
                    name: 'Acesse',
                    icone: 'fas fa-shield-alt',
                    color: '#DC3545',
                    textcolor: true,
                    link: '/arquivos/politica.pdf',
                  }"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardHomeMultiple from "./components/CardHomeMultiple.vue";
import CardHomeSingle from "./components/CardHomeSingle.vue";

export default {
  components: {
    CardHomeSingle,
    CardHomeMultiple,
  },
  data() {
    return {
      CardAberto: "",
    };
  },
  methods: {
    Fechar(info) {
      this.CardAberto = info;
    },
  },
};
</script>

<style scoped>
@import "./styles/Home.css";

.imagem-home {
  background-image: url("../assets/img-minhastar/teste.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  width: calc(100% - 16px);
  height: calc(100vh - 16px);
  z-index: -1;
}

.fundo_tudo {
  width: calc(100% - 16px);
  height: calc(100vh - 16px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Acesso a:hover {
  cursor: pointer;
  color: #222222;
  transition: all 0.8s ease;
}

.Acesso a {
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  transition: all 0.8s ease;
}

@media screen and (max-width: 1399px) {
  .imagem-home {
    height: 100%;
  }
}
</style>
